
import { Component, Watch, Vue } from 'vue-property-decorator';
import { NewClientModalTabs } from '@/enums/components/EditClient/NewClientModalTabs';
import CustomerInformation from '@/components/global/client/CustomerInformation.vue';
import ContactInformation from '@/components/global/client/ContactInformation.vue';
import PaymentType from '@/models/PaymentType';
import Country from '@/models/Country';
import PriceType from '@/models/PriceType';
import PriceListRegion from '@/models/PriceListRegion';
import ContactPerson from '@/models/ContactPerson';
import ContactConfig from '@/models/interfaces/Contact';
import AddressConfig from '@/models/interfaces/Address';
import Address from '@/models/Address';
import Client from '@/models/Client';
import { RouteNames } from '@/enums/routes/RouteNames';
import { AvailableClientFields } from '@/interfaces/components/clients/AvailableClientFields';
import ClientType from '@/models/ClientType';
import ClientTypeRepository from '@/repositories/ClientTypeRepository';
import PaymentTypeRepository from '@/repositories/PaymentTypeRepository';
import ProductGroup from '@/models/ProductGroup';
import ProjectModel from '@/models/Project';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { PopupEvents } from '@/enums/global/PopupEvents';
import CMSUser from '@/models/CMSUser';
import ProjectTabLead from '@/components/views/NewProjectView/ProjectTabLead.vue';
import ProjectRepository from '@/repositories/ProjectRepository';
import Project from '@/models/Project';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import { AxiosResponse } from 'axios';
import ProjectAttachmentsNew from '@/components/global/ProjectAttachmentsNew.vue';
import ProjectShellComponent from '@/components/views/NewProjectView/ProjectShellComponent.vue';
import LeadDetailsAndForm from '@/components/views/NewProjectView/Lead/LeadDetailsAndForm.vue';
import DownloadLeadPDF from '@/components/views/NewProjectView/Lead/DownloadLeadPDF.vue';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import { ErrorOptions } from '@/interfaces/ErrorOptions';

@Component({
    name: 'NewClientModal',
    components: {
        CustomerInformation,
        ContactInformation,
        ProjectTabLead,
        LeadDetailsAndForm,
        ProjectAttachmentsNew,
        ProjectShellComponent,
        DownloadLeadPDF,
    },
})
export default class NewClientModal extends Vue {
    private emailValidationOverride: boolean = false;
    private emailValidationOverrideValue: boolean = false;
    private formData: any = { ...this.templateFormData() };
    private isNewClientModalVisible = false;
    private mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private selectedCountry: string = '23';
    private validationErrors: string[] = [];
    private refErrors: string[] = [];
    private projectId = null;
    private clientId = null;
    private contactPersonId = null;
    private addressId = null;
    private contactpersonaddressId = null;
    private project: Project | null = null;
    private defaultCountry = '23';
    private isProjectLocked = false;
    private lockEditing = false;

    private async createObjects() {
        const data = this.formData as AvailableClientFields;
        let newClientId = null;
        let newContactPersonId = null;
        this.formatDataForUpdate(data);
        try {
            data.contactPersons = await this.createContacts();
            newContactPersonId = data.contactPersons;
            newClientId = await this.createClient(data);
        } catch (e) {
            const error = e as ErrorOptions;

            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error.response ? error.response.data.meta.message : error.message,
            });
            return;
        }
        const response = (await ProjectModel.createNew(newClientId)) as AxiosResponse;
        const project = ProjectRepository.getProjectById(response.data.id);

        if (!project) {
            throw new Error('There was an error while creation a new project.');
        } else {
            this.projectId = response.data.id;
            this.project = project;
            this.clientId = newClientId;
            this.contactPersonId = newContactPersonId[0];
            this.formData = this.emptyFormData();
        }
    }

    private get tabs() {
        return [NewClientModalTabs.CUSTOMER];
    }

    private loadProject() {
        // TODO -> redirect to project/home
    }

    private handleSelectedCountryChanged(e: any) {
        this.selectedCountry = e;
    }

    private emptyFormData() {
        return {
            isCompany: false,
            name: undefined,
            email: '',
            phone: '',
            addressForm: {
                streetNameAndNumber: undefined,
                poNumber: undefined,
                city: undefined,
                building: undefined,
                country: '23',
            },
            address: null,
            taxId: undefined,
            registerId: undefined,
            isTaxPayer: undefined,
            bankCode: undefined,
            currency: undefined,
            paymentType: undefined,
            priceType: undefined,
            priceListRegion: undefined,
            assignedUser: undefined,
            tags: [],
            additionalNotes: undefined,
            contacts: [],
            discounts: [],
            contactPersons: [''],
            discount_ids: [''],
            clientTypes: [],
        };
    }

    private templateFormData() {
        return {
            isCompany: false,
            name: 'NewClient' + String(Math.random()),
            email: 'newtemplateclient@mail.com',
            phone: 'nophone',
            addressForm: {
                streetNameAndNumber: 'undefined',
                poNumber: 'undefined',
                city: 'undefined',
                building: '',
                country: '23',
            },
            address: null,
            taxId: undefined,
            registerId: undefined,
            isTaxPayer: undefined,
            bankCode: undefined,
            currency: undefined,
            paymentType: undefined,
            priceType: undefined,
            priceListRegion: undefined,
            assignedUser: undefined,
            salesman: 'Borut Črv',
            tags: [],
            additionalNotes: undefined,
            contacts: [],
            discounts: [],
            contactPersons: [''],
            discount_ids: [''],
            clientTypes: [],
        };
    }

    private openDuplicateClientPopup(duplicate: Client) {
        EventBus.$emit(EventBusEvents.openDuplicateClientPopup, {
            popupEvent: PopupEvents.openDuplicateClient,
            data: {
                client: duplicate,
            },
        });
    }

    private confirmFieldsAreValid() {
        this.validationErrors = [];
        this.refErrors = [];

        let flag = true;

        if (!this.formData.name) {
            flag = false;
            this.validationErrors.push(this.$t('Polje ime je obavezno'));
            this.refErrors.push('name');
        }

        if (!this.formData.addressForm.streetNameAndNumber) {
            this.formData.addressForm.streetNameAndNumber = 'Nedefinirano';
        }

        if (!this.formData.addressForm.poNumber) {
            this.formData.addressForm.poNumber = 'Nedefinirano';
        }

        if (!this.formData.addressForm.city) {
            this.formData.addressForm.city = 'Nedefinirano';
        }

        if (!this.formData.addressForm.country) {
            this.formData.addressForm.country = '23';
        }

        if (!this.formData.phone) {
            this.formData.phone = 'nedefiniranTelefon' + String(Math.floor(Math.random() * 10000));
        }

        if (!this.formData.email) {
            this.formData.email = this.formData.phone + '@nomail.com';
        } else {
            this.emailValidationOverride = false;
            this.emailValidationOverrideValue = true;
        }

        return flag;
    }

    private async confirmEmailsAreValid() {
        const customerEmail = this.formData.email;
        const contactEmails = this.formData.contacts.map((contact: any) => {
            return contact.email;
        });

        const emails = [customerEmail, ...contactEmails];
        const clients: Client[] = [];

        for await (const email of emails) {
            if (email && !email.match(this.mailFormat)) {
                this.$notification.error({
                    message: this.$t('Invalid email'),
                    description: '',
                });
                this.loadingOverlay.stop();

                return false;
            }

            const duplicate = await this.checkDuplicateEmails(email);
            if (duplicate) {
                clients.push(duplicate);

                this.openDuplicateClientPopup(duplicate);
                this.loadingOverlay.stop();

                return false;
            }
        }

        if (clients.length === 0) {
            return Promise.resolve(true);
        }
    }

    private async checkDuplicateEmails(input: string) {
        let clients = null;

        try {
            clients = await Client.validateEmail(input);
        } catch (e) {
            return null;
        }

        return clients.data.length <= 0 ? null : clients.data[0];
    }

    private isCustomerInfoShown(tab: string) {
        return tab === NewClientModalTabs.CUSTOMER;
    }

    private async createNewClientAndProject() {
        this.loadingOverlay.start();

        const data = this.formData as AvailableClientFields;
        this.formatDataForUpdate(data);

        if (!this.confirmFieldsAreValid()) {
            this.$notification.error({
                message: this.validationErrors.join('!\n'),
                description: '',
            });

            this.loadingOverlay.stop();

            return;
        }

        const emailsAreValid = await this.confirmEmailsAreValid();

        if (!emailsAreValid) {
            this.loadingOverlay.stop();
            return;
        }

        if (this.projectId) {
            EventBus.$emit('save-form');
        } else {
            this.loadingOverlay.stop();
            this.closePopup();
            throw new Error('There was error updating the project');
        }

        data.clientTypes = this.findClientTypeId(data);
        data.paymentType = this.findPaymentTypeId(data);
        if (this.clientId && this.contactPersonId && this.addressId) {
            data.contactPersons[0] = this.contactPersonId;
            for (const contact of this.formData.contacts as ContactConfig[]) {
                if (contact.name) {
                    if (contact.address && this.hasAddressData(contact.address)) {
                        // tslint:disable: no-shadowed-variable
                        if (contact.address.country) {
                            contact.address.country = contact.address.country.split('|')[0];
                        }
                        contact.address.id = this.addressId;
                        const resp = await Address.updateExisting(String(this.addressId), contact.address);
                        const resp2 = await Address.updateExisting(
                            String(this.contactpersonaddressId),
                            contact.address
                        );
                    }
                    const resp = await ContactPerson.updateExisting(data.contactPersons[0], contact);
                }
            }
            await Client.updateExisting(String(this.clientId), data);
            if (this.projectId) {
                await this.$router.push({
                    name: RouteNames.project,
                    params: {
                        id: String(this.projectId),
                    },
                    query: {
                        initialTab: `${ProjectTabValues.Lead}`,
                    },
                });
            }
        } else {
            this.loadingOverlay.stop();
            this.closePopup();
            throw new Error('There was error updating the client');
        }
        this.isNewClientModalVisible = false;
        this.loadingOverlay.stop();
    }

    private formatDataForUpdate(data: AvailableClientFields) {
        if (data.addressForm && !data.addressForm.country) {
            data.addressForm.country = this.defaultCountry;
        }

        if (typeof data.tags[0] !== 'string') {
            data.tags = data.tags.map((tag: any) => tag.name);
        }

        if (!data.isCompany) {
            data.contacts = [
                {
                    address: {
                        streetNameAndNumber: data.addressForm.streetNameAndNumber,
                        poNumber: data.addressForm.poNumber,
                        city: data.addressForm.city,
                        building: data.addressForm.building,
                        country: data.addressForm.country,
                    },
                    name: data.name,
                    email: data.email,
                    phone: data.phone,
                    job: undefined,
                    useCompanyAddress: true,
                },
            ];
        }
    }

    private async createClient(data: AvailableClientFields) {
        if (data.tags.length > 0) {
            data.clientTypes = data.tags;
        } else {
            data.clientTypes = this.findClientTypeId(data);
        }
        data.paymentType = this.findPaymentTypeId(data);
        if (this.hasAddressData(data.addressForm)) {
            // tslint:disable: no-shadowed-variable
            const resp = await Address.createNew(data.addressForm);
            data.address = resp.data.id;
            this.addressId = resp.data.id;
        }

        const resp = await Client.createNew(data);
        return resp.data.id;
    }

    private async createContacts() {
        const contacts = [];
        for (const contact of this.formData.contacts as ContactConfig[]) {
            if (contact.name) {
                if (contact.address && this.hasAddressData(contact.address)) {
                    // tslint:disable: no-shadowed-variable
                    if (contact.address.country) {
                        contact.address.country = contact.address.country.split('|')[0];
                    }

                    const resp = await Address.createNew(contact.address);
                    contact.address.id = resp.data.id;
                    this.contactpersonaddressId = resp.data.id;
                }

                const resp = await ContactPerson.createNew(contact);
                contacts.push(resp.data.id);
            }
        }
        return contacts;
    }

    private hasAddressData(address?: AddressConfig) {
        return (
            !!address?.streetNameAndNumber ||
            !!address?.poNumber ||
            !!address?.city ||
            !!address?.building ||
            !!address?.country
        );
    }

    private findClientTypeId(data: AvailableClientFields) {
        const code = data.isCompany ? 'FIRM' : 'CUSTOMER';
        const typeId = this.clientTypes.find((type) => type.code === code)?.id;
        return [typeId];
    }

    private findPaymentTypeId(data: AvailableClientFields) {
        if (!!data.paymentType) {
            return data.paymentType;
        }
        if (this.paymentTypes.find((type) => type.isDefault)) {
            return this.paymentTypes.find((type) => type.isDefault)?.id;
        } else {
            return PaymentType.fetchDefaultPaymentType();
        }
    }
    private get clientTypes() {
        return ClientTypeRepository.getAll();
    }
    private get paymentTypes() {
        return PaymentTypeRepository.getAllWithArchived();
    }

    private closePopup() {
        this.$router.go(-1);
        this.isNewClientModalVisible = false;
    }
    private get salesmen() {
        return CMSUserRepository.getAllSalesmen();
    }
    private async fetchInitialClientData() {
        try {
            await Promise.allSettled([
                PaymentType.getAll(),
                ClientType.getAll(),
                Country.getAll(),
                PriceType.getAll(),
                PriceListRegion.getAll(),
                ProductGroup.getAll(),
                CMSUser.getAllOnlyUsers(),
            ]);
        } catch (e) {
            Promise.reject(e);
        }
    }

    private async created() {
        this.loadingOverlay.start();
        await this.fetchInitialClientData();
        await this.createObjects();
        this.loadingOverlay.stop();
        this.isNewClientModalVisible = true;
    }

    @Watch('formData.email')
    private onEmailChange(newVal: string, oldVal: string) {
        if (this.isNewClientModalVisible) {
            if (this.formData.contacts.length) {
                // @ts-ignore
                this.formData.contacts[0].email = newVal;
            }
        }
    }

    @Watch('formData.contacts.length')
    private onFirstContactCreate(newVal: number) {
        if (this.isNewClientModalVisible) {
            if (newVal !== 1) {
                return;
            }

            if (this.formData.isCompany && this.formData.email) {
                // @ts-ignore
                this.formData.contacts[0].email = this.formData.email;
            }
        }
    }
}
